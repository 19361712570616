{
  "daytime": {
    "earlier": "AM",
    "later": "PM"
  },
  "login": {
    "title": "Iniciar sesión",
    "text": "Iniciar sesión en tu cuenta",
    "button": "Iniciar sesión",
    "stressLevel": "Stress Level",
    "errorDialog": {
      "message": "Usuario o contraseña incorrectas",
      "accept": "De acuerdo"
    },
    "placeholder": {
      "username": "Por favor,  introduce tu nombre de usuario",
      "password": "Por favor, introduce tu contraseña"
    }
  },
  "sidebar": {
    "bulletins": "Avalanche.report",
    "translations": "Translations",
    "dangerSources": "Danger Sources",
    "observations": "Observaciones",
    "qfa": "FAQ",
    "modelling": "Modelización",
    "modellingForecast": "Previsión",
    "modellingZamg": "ZAMG multi modelo",
    "modellingZamgECMWF": "ZAMG ECMWF-EPS",
    "modellingZamgCLAEF": "ZAMG CLAEF-EPS",
    "modellingZamgMeteogram": "Meteograma GeoSphere",
    "modellingSnowpack": "modelo SNOWPACK",
    "modellingSnowpackMeteo": "SNOWPACK modelled meteo",
    "weatherbox": "Weatherbox",
    "awsome": "AWSOME",
    "snowpack": "SNOWPACK",
    "geosphere": "GeoSphere"
  },
  "menu": {
    "logout": "Cerrar sesión",
    "language": "Idioma",
    "en": "Inglés",
    "de": "Alemán",
    "fr": "Francés",
    "it": "Italiano",
    "es": "Español",
    "ca": "Catalán",
    "oc": "Aranés",
    "fullscreen": "Pantalla completa",
    "settings": "Ajustes",
    "statistics": "Estadisticas",
    "admin": "Admin",
    "region": "Región",
    "education": "Educación"
  },
  "matrix": {
    "label": {
      "snowpackStability": "Estabilidad del manto nivoso",
      "frequency": "Frecuencia",
      "avalancheSize": "Tamaño de alud",
      "dangerRating": "Clasificación del peligro",
      "override": "(modificar grado de peligro)",
      "matrix": "Matriz",
      "referToFairStability": "Referirse a estabilidad regular",
      "referToPoorStability": "Referirse a estabilidad pobre",
      "dangerLevel1": "Grado de peligro 1 (Débil)"
    }
  },
  "dangerSources": {
    "status": {
      "missing": "ausente",
      "available": "available",
      "forecast": "forecast",
      "analysis": "analysis",
      "loading": "Loading danger sources ...",
      "saveError": "Danger source could not be saved!",
      "loadDangerSourcesError": "Danger sources could not be loaded!",
      "loadVariantsError": "Danger source variants could not be loaded!"
    },
    "variantStatus": {
      "active": "active",
      "dormant": "dormant",
      "inactive": "inactive"
    },
    "tooltip": {
      "read": "Read danger sources",
      "edit": "Edit danger sources"
    },
    "table": {
      "loadMore": "Load {{count}} more days",
      "title": {
        "forecast": "Previsión",
        "analysis": "Analysis"
      }
    },
    "create": {
      "variantStatus": "Status",
      "variantEditable": "This variant can be edited.",
      "variantNotEditable": "This variant can not be edited.",
      "noVariantsForSelectedDay": "No variants defined for this date.",
      "tooltip": {
        "editDangerSource": "Edit danger source",
        "loadVariants": "Load variants from the day before",
        "edit": "Editar micro-regiones",
        "copy": "Copy variant",
        "delete": "Delete variant",
        "compare": "Compare variant",
        "forecast": "Compare with forecast"
      },
      "createDangerSource": "Create danger source",
      "createVariant": "Create new variant",
      "noDangerSources": "No danger sources",
      "title": {
        "avalanche": "Alud",
        "matrix": "Matriz",
        "characteristics": "Características"
      },
      "label": {
        "glidingSnowActivity": "Gliding snow activity",
        "snowHeight": "Snow height",
        "zeroDegreeIsotherm": "0°C isotherm",
        "looseSnowMoisture": "Loose snow moisture",
        "looseSnowGrainShape": "Loose snow grain shape",
        "slabGrainShape": "Slab grain shape",
        "slabThickness": "Slab thickness",
        "slabHandHardness": "Slab hand hardness",
        "slabHardnessProfile": "Slab hardness",
        "slabEnergyTransferPotential": "Slab energy transfer potential",
        "slabDistribution": "Slab distribution",
        "weakLayerGrainShape": "Weak layer grain shape",
        "weakLayerGrainSize": "Weak layer grain size",
        "weakLayerPersistent": "Weak layer persistent",
        "weakLayerThickness": "Weak layer thickness",
        "weakLayerStrength": "Weak layer strength",
        "weakLayerWet": "Weak layer wet",
        "weakLayerCrustAbove": "Weak layer crust above",
        "weakLayerCrustBelow": "Weak layer crust below",
        "weakLayerPosition": "Weak layer position",
        "weakLayerCreation": "Weak layer creation",
        "weakLayerDistribution": "Weak layer distribution",
        "dangerSpotRecognizability": "Danger spot recognizability",
        "remoteTriggering": "Remote triggering",
        "hasDaytimeDependency": "Dependencia diurna",
        "dangerIncreaseWithElevation": "Danger increase with elevation",
        "runoutIntoGreen": "Runout into green",
        "highestDangerAspect": "Highest danger aspect",
        "dangerPeak": "Danger peak",
        "slopeGradient": "Gradiente de pendiente",
        "naturalRelease": "Natural release",
        "terrainTypes": "Terrain types",
        "dangerSigns": "Danger signs",
        "aspects": "Orientaciones"
      },
      "editDangerSourceDialog": {
        "save": "Guardar",
        "cancel": "Cancelar"
      }
    }
  },
  "avalancheType": {
    "title": "Tipo de alud",
    "label": {
      "slab": "SLAB",
      "loose": "LOOSE",
      "glide": "GLIDE"
    },
    "tooltip": {
      "slab": "Slab avalanche",
      "loose": "Loose snow avalanche",
      "glide": "Glide snow avalanche"
    }
  },
  "elevation": {
    "title": "Altitud",
    "high": "Debajo",
    "low": "Encima",
    "treeline": "Límite superior del bosque"
  },
  "bulletins": {
    "title": "Avalanche.report",
    "tooltip": {
      "edit": "Edit bulletin",
      "read": "Read bulletin",
      "copy": "Copiar boletín",
      "paste": "Pegar boletín",
      "cancel": "Cancelar copia",
      "update": "Editar",
      "submit": "Enviar",
      "submitUpdate": "Enviar actualización",
      "publishChange": "Publicar ahora (sin mensajes)",
      "publishUpdate": "Publicar ahora",
      "caaml": "Muestra CAAML",
      "json": "Muestra JSON",
      "create": "Crear boletín",
      "preview": "Vista previa del boletín (PDF)",
      "check": "Comprobar boletín",
      "info": "Muestra estado de la publicación",
      "publishAll": "Publicar todas las regiones",
      "mediaFile": "Subir archivo multimedia"
    },
    "status": {
      "submittedInfo": "Será publicado a las 5PM",
      "resubmittedInfo": "Será publicado a las 8AM",
      "publishedInfo" : "Ha sido publicado",
      "noPublicationInfo" : "NO será publicado automàticamente",
      "loadingPreview": "Creando vista previa en PDF ...",
      "loading": "Cargando boletín ...",
      "loadMoreBulletins": "Load {{count}} more bulletins",
      "publishing": "Publicando boletín ...",
      "submitting": "Enviando boletín ...",
      "saveError": "Bulletin could not be saved!",
      "loadBulletinsError": "Bulletins could not be loaded!"
    },
    "create": {
      "tooltip": {
        "edit": "Editar micro-regiones",
        "copy": "Copiar región",
        "delete": "Borrar región",
        "undo": "Undo last change in region",
        "redo": "Redo change in region",
        "createRegion": "Crear región",
        "cancel": "Cancelar",
        "acceptSuggestion": "Aceptar la región sugerida",
        "rejectSuggestion": "Rechazar la región sugerida",
        "saveChanges": "Guardar los cambios",
        "discardChanges": "Descartar los cambios",
        "incompleteAvalancheProblem": "Problema de aludes incompleto",
        "saveBulletin": "Guardar el boletín",
        "discardBulletin": "Descartar los cambios del boletín",
        "loadBulletin": "Cargar el boletín del día anterior",
        "uploadJsonBulletin": "Subir el boletín en formato JSON",
        "downloadJsonBulletin": "Descargar el boletín en formato JSON",
        "daytimeDependency": "Dependencia diurna",
        "treeline": "Límite superior del bosque",
        "tendency": {
          "decreasing": "El peligro de aludes desciende",
          "steady": "El peligro de aludes se mantiene igual",
          "increasing": "El peligro de aludes aumenta",
          "danger": "Peligro de aludes"
        },
        "textcat": {
          "edit": "Editar",
          "copy": "Copiar",
          "paste": "Pegar",
          "example": "Cargar texto de ejemplo",
          "delete": "Borrar"
        },
        "moveUpAvalancheProblem": "Mover el problema de aludes hacia arriba",
        "moveDownAvalancheProblem": "Mover el problema de aludes hacia abajo",
        "dangerRatingDirectionUp": "Hacia arriba",
        "dangerRatingDirectionDown": "Hacia abajo"
      },
      "save": "Guardar",
      "discard": "Descartar",
      "back": "Volver",
      "createAggregatedRegion": "Nueva región",
      "pasteAggregatedRegion": "Pegar región",
      "pendingSuggestions": "Sugerencias",
      "rejectSuggestion": "Rechazar",
      "acceptSuggestion": "Aceptar",
      "cancelCopyBulletin": " Cancelar",
      "createAvalancheProblem": "Nuevo problema",
      "ownRegions": "Regiones propias",
      "foreignRegions": "Regiones extranjeras",
      "externalRegions": "Regiones externas",
      "mapSelectRegion": "Seleccionar regiones en el mapa",
      "statusHeader": "Estado del boletín",
      "suggestions": "Sugerencias",
      "noBulletinsForSelectedDay": "Ninguna región definida para esta fecha",
      "autosave": "Guardado automáticamente",
      "regionEditable": "Esta región puede ser editada.",
      "regionNotEditable": "Esta región no puede editar-se.",
      "regionLocked": "Esta región está bloqueada.",
      "changeDangerRatingElevation": "Extender el peligro de aludes",
      "mapTop": "Mapa arriba",
      "mapLeft": "Mapa al lado",
      "showNotes": "Mostrar notas",
      "hideNotes": "Ocultar notas",
      "incompleteAvalancheProblem": "Incomplete",
      "label": {
        "avalancheProblems": "Problema de aludes",
        "aspects": "Orientaciones",
        "highlights": "Alerta especial",
        "avActivityHighlights": "Titular de la situación de aludes",
        "avActivityComment": "Descripción del peligro de aludes",
        "snowpackStructureComment": "Descripción de la estructura del manto",
        "dangerPatterns": "Patrón de peligro",
        "tendencyComment": "Descripción de la tendencia",
        "synopsisComment": "Weather description and forecast",
        "generalHeadlineComment": "Overall headline for report",
        "notes": "Notas"
      },
      "copyRegion": {
        "label": "Copiar región de alerta a otro boletín",
        "select": "Pegar en ...",
        "currentBulletin": "Este boletín"
      },
      "title": {
        "avalancheProblem": "Problemas de aludes",
        "dangerDescription": "Descripción del peligro de aludes",
        "forenoon": "Mañana",
        "afternoon": "Tarde",
        "snowpackStructure": "Estructura del manto",
        "tendency": "Tendencia",
        "weather": "Weather",
        "showTranslations": "Mostrar traducciones",
        "hideTranslations": "Ocultar traducciones"
      },
      "placeholder": {
        "notes": "Escribir aquí..."
      },
      "pmDialog": {
        "noIFrameSupport": "Tu navegador no soporta IFrames!"
      },
      "discardDialog": {
        "message": "¿Quieres descartar los cambios?",
        "accept": "Si",
        "reject": "No"
      },
      "deleteAggregatedRegionDialog": {
        "message": "¿Quieres borrar esta región?",
        "accept": "Si",
        "reject": "No"
      },
      "noRegionDialog": {
        "message": "Al menos una región debe ser tuya",
        "accept": "De acuerdo"
      },
      "loadDialog": {
        "message": "¿Quieres cargar los boletines del día anterior?",
        "accept": "Si",
        "reject": "No"
      },
      "loadAutoSaveDialog": {
        "message": "¿Quieres cargar automáticamente los boletines guardados?",
        "accept": "Si",
        "reject": "No"
      },
      "saveErrorDialog": {
        "message": "¡Se ha producido un error guardando los boletines!",
        "accept": "De acuerdo"
      },
      "changeErrorDialog": {
        "message": "Se ha producido un error cargando los boletines",
        "accept": "De acuerdo"
      },
      "loadingErrorDialog": {
        "message": "Se ha producido un error cargando los boletines",
        "accept": "De acuerdo"
      },
      "loadingJsonFileErrorDialog": {
        "message": "¡Hubo un error al cargar los boletines desde el archivo JSON!",
        "accept": "De acuerdo"
      },
      "avalancheProblemErrorDialog": {
        "message": "Falta el problema de alud, las orientaciones, la estabilidad del manto nivoso, la frecuencia y/o el tamaño de alud para al menos un problema de alud",
        "accept": "De acuerdo"
      },
      "loadAvActivityCommentExampleTextDialog": {
        "message": "¿Para que situación quieres cargar un texto de ejemplo?",
        "newSnow": "Nieve reciente",
        "windSlab": "Placas de viento",
        "persistentWeakLayers": "Capas débiles persistentes",
        "wetSnow": "Nieve húmeda",
        "glidingSnow": "Deslizamientos basales",
        "favourableSituation": "Situación favorable",
        "cancel": "Cancelar"
      },
      "removeDaytimeDependencyDialog" : {
        "message": "Which avalanche problems do you want to keep?",
        "cancel": "Cancelar"
      },
      "strategicMindsetDialog" : {
        "title": "Strategic Mindsets",
        "introduction": "The \"Strategic Mindsets\" are intended to express our mental attitude with regard to the avalanche situation and its development in the corresponding warning region. It should not be derived directly from the hazard assessment, but rather understood and used as an intuitive impression of the overall situation. The \"Strategic Mindsets\" are therefore primarily a communication tool. Using the mindsets and associated definitions, we forecasters can quickly and efficiently exchange information about the development of the hazard situation and find a common language for communication.",
        "description": {
          "title": "Description:",
          "text": {
            "assessment": "There is <b>great uncertainty</b> in the risk assessment due to <b>insufficient information</b> from the site.",
            "stepping_out": "<b>Decreasing tendency</b> of the hazard situation (also within a danger level).",
            "status_quo": "Same hazardous situation. <b>No significant change</b> compared to the previous day.",
            "stepping_back": "<b>Increasing tendency</b> of the hazard situation (also within a danger level).",
            "entrenchment": "<b>Almost constant, tense hazardous situation.</b> The main danger comes from (a) <b>persistent weak layer</b>(s) susceptible to interference.",
            "free_ride": "<b>Low avalanche danger</b> with generally stable snow cover. Only small avalanches are possible in extremely steep terrain. ",
            "high_alert": "Exceptional situation with <b>high or very high avalanche danger</b>. Spontaneous avalanches of <b>very large or extreme magnitude</b> are possible",
            "spring_diurnal": "Wet snow avalanches during the course of the day, which are subject to a <b>clear diurnal cycle</b>, are decisive for the risk assessment."
          }
        },
        "communication": {
          "title": "Communication:",
          "text": {
            "assessment": "Avalanche situation must be checked locally. Restraint is recommended.",
            "stepping_out": "Gradual decrease in danger. However, caution is still required and the improving situation should be assessed locally before entering riskier terrain.  ",
            "status_quo": "Hazardous situation is unchanged. Feedback from observers helps to better outline the hazardous situation. More favourable terrain should be communicated with emphasis. ",
            "stepping_back": "Clear communication of the increase in avalanche danger - even within a danger level. Information on the causes supports comprehensibility in communication. ",
            "entrenchment": "Caution and restraint are required. Situation hardly changes. If possible, provide as precise information as possible on distribution and possibility to trigger. Increased communication of observations that reveal the situation and trigger restraint.",
            "free_ride": "Little text. Emphasise the generally favourable overall situation and potentially isolated hazard areas",
            "high_alert": "Communication across all channels. The focus is no longer on winter sports. Danger even in areas that are not usually at risk of avalanches.",
            "spring_diurnal": "Favourable situation in the morning, increasing during the day. Assessment should be made locally by observing the softening of the melt-freeze crust and the increasing soaking of the snow cover."
          }
        },
        "examples": {
          "title": "Examples:",
          "text": {
            "assessment": "Start of the season.",
            "stepping_out": "Cooling leads to a decrease in the risk of wet snow avalanches after a warm and humid weather phase.",
            "status_quo": "Consistent wind-slab problem with cold temperatures, loose snow and wind.",
            "stepping_back": "Abundant fresh snowfall leads to an increase in the danger situation.",
            "entrenchment": "Critical persistent weak layers problem with potential for dangerously large avalanches. The situation is only stabilising very slowly and requires a great deal of restraint.",
            "free_ride": "Low avalanche danger, no persistent weak layers, all terrain has already been groomed.",
            "high_alert": "Danger level 4 or higher due to large amounts of fresh snow and/or problematic, persistent weak layers. ",
            "spring_diurnal": "Spring situation with dry high-pressure weather and moderate temperatures with otherwise mostly stable old snow cover."
          }
        },
        "strategicMindset": {
          "assessment": "High Uncertainty",
          "stepping_out": "Improving",
          "status_quo": "Status Quo",
          "stepping_back": "Deteriorating",
          "entrenchment": "Persistent Unstable",
          "free_ride": "Favorable",
          "high_alert": "High Alert",
          "spring_diurnal": "Spring Diurnal"
        }
      },
      "decisionTree" : {
        "filepath": "./assets/img/decision_tree-en.svg",
        "feedbackURL": "https://models.avalanche.report:8061/en/",
        "decisionTree" : "Árbol de decisión",
        "feedback" : "Comentarios",
        "save": "Guardar",
        "discard": "Descartar"
      }
    },
    "table": {
      "title": {
        "date": "Fecha",
        "status": {
          "AT-02": "Carintia",
          "AT-03": "Baja Austria",
          "AT-04": "Alta Austria",
          "AT-05": "Salzburgo",
          "AT-06": "Estiria",
          "AT-07": "Tirol",
          "AT-08": "Vorarlberg",
          "CH" : "Suiza",
          "DE-BY" : "Baviera",
          "GEOSPHERE": "GEOSPHERE",
          "IT-32-TN": "Trentino",
          "IT-32-BZ": "Sud Tirol",
          "ES-CT-L": "Aran"
        }
      },
      "status": {
        "missing": "ausente",
        "draft": "borrador",
        "submitted": "enviado",
        "published": "publicado",
        "updated": "actualizado",
        "resubmitted": "reenviado",
        "republished": "republicado"
      },
      "publishBulletinsDialog": {
        "message": "¿Quieres publicar el boletín?",
        "missingDangerRating": "Grado de peligro no establecido",
        "missingRegion": "Regiones no establecidas",
        "duplicateRegion": "Regiones establecidas varias veces",
        "missingAvActivityHighlights": "Falta el titular de la situación de aludes",
        "missingAvActivityComment": "Falta la descripción del peligro de aludes",
        "missingSnowpackStructureHighlights": "Falta el titular de la estructura del manto",
        "missingSnowpackStructureComment": "Falta la descripción de la estructura del manto",
        "pendingSuggestions": "Sugerencias pendientes",
        "incompleteTranslation": "Traducción incompleta",
        "accept": "Si",
        "reject": "No"
      },
      "publicationStatusDialog": {
        "headline": "Estado de publicación",
        "publishedBy": "Publicado por:",
        "publishedAt": "Publicado a las:",
        "validFor": "Válido para:",
        "title": {
          "prod": "Cosas serias",
          "test": "Test system",
          "task": "Tarea",
          "language": {
            "all": "TODOS",
            "de": "DE",
            "it": "IT",
            "en": "EN"
          }
        },

        "caaml": {
          "label": "Ficheros CAAML",
          "tooltip": "Crear ficheros CAAML",
          "success": "{{prefix}}¡Archivos CAAML creados con éxito!",
          "error": "{{prefix}}¡No se pudieron crear los archivos CAAML!"
        },
        "map": {
          "label": "Mapas",
          "tooltip": "Crear mapas",
          "success": "{{prefix}}¡Mapas creados con éxito!",
          "error": "{{prefix}}¡No se pudieron crear los mapas!"
        },
        "pdf": {
          "label": "PDFs",
          "tooltip": "Crear PDFs",
          "success": "{{prefix}}¡PDFs creados con éxito!",
          "error": "{{prefix}}¡No se pudieron crear archivos PDF!"
        },
        "html": {
          "label": "HTMLs simples",
          "tooltip": "Crear archivos HTML simples",
          "success": "{{prefix}}¡Archivos HTML simples creados con éxito!",
          "error": "{{prefix}}¡No se pudieron crear archivos HTML simples!"
        },
        "email": {
          "label": "Correos electrónicos",
          "tooltip": "Enviar correos electrónicos",
          "success": "{{prefix}}¡Correos electrónicos enviados con éxito!",
          "error": "{{prefix}}¡No se pudieron enviar los correos electrónicos!"
        },
        "telegram": {
          "label": "Mensajes de Telegram",
          "tooltip": "Enviar mensajes de Telegram",
          "success": "{{prefix}}¡Mensajes de Telegram enviados con éxito!",
          "error": "{{prefix}}¡No se pudieron enviar los mensajes de Telegram!"
        },
        "push": {
          "label": "Notificaciones push",
          "tooltip": "Enviar notificaciones push",
          "success": "{{prefix}}¡Notificaciones push enviadas con éxito!",
          "error": "{{prefix}}¡No se pudieron enviar notificaciones push!"
        },
        "accept": "De acuerdo"
      },
      "mediaFileDialog": {
        "headline": "Archivo multimedia",
        "important": "IMPORTANTE",
        "missingFile": "¡Ningún archivo seleccionado!",
        "uploadError": "¡Subida fallida!",
        "accept": "Enviar",
        "reject": "Cancelar"
      },
      "publishBulletinsErrorDialog": {
        "message": "¡Se ha producido un error publicando los boletines!",
        "accept": "De acuerdo"
      },
      "submitBulletinsDialog": {
        "message": "¿Quieres enviar el boletín?",
        "missingDangerRating": "Grado de peligro no establecido",
        "missingRegion": "Regiones no establecidas",
        "missingAvActivityHighlights": "Falta el titular de la situación de aludes",
        "missingAvActivityComment": "Falta la descripción del peligro de aludes",
        "missingSnowpackStructureHighlights": "Falta el titular de la estructura del manto",
        "missingSnowpackStructureComment": "Falta la descripción de la estructura del manto",
        "pendingSuggestions": "Sugerencias pendientes",
        "accept": "Si",
        "reject": "No"
      },
      "submitBulletinsErrorDialog": {
        "message": "¡Se ha producido un error enviando los boletines!",
        "accept": "De acuerdo"
      },
      "submitBulletinsDuplicateRegionDialog": {
        "message": "¡Regiones establecidas varias veces! Los boletines no se pueden enviar.",
        "accept": "De acuerdo"
      },
      "checkBulletinsErrorDialog": {
        "message": "Se ha producido un error comprobando los boletines",
        "accept": "De acuerdo"
      },
      "previewErrorDialog": {
        "message": "¡No se pudo cargar la vista previa!",
        "accept": "De acuerdo"
      },
      "checkBulletinsDialog": {
        "message": "Comprobar boletín",
        "missingDangerRating": "Grado de peligro no establecido",
        "missingRegion": "Regiones no establecidas",
        "missingAvActivityHighlights": "Falta el titular de la situación de aludes",
        "missingAvActivityComment": "Falta la descripción del peligro de aludes",
        "missingSnowpackStructureHighlights": "Falta el titular de la estructura del manto",
        "missingSnowpackStructureComment": "Falta la descripción de la estructura del manto",
        "pendingSuggestions": "Sugerencias pendientes",
        "incompleteTranslation": "Traducción incompleta",
        "ok": "(todo bien)",
        "accept": "De acuerdo"
      },
      "publishAllDialog": {
        "message": "¿Quieres publicar todas las regiones?",
        "accept": "Si",
        "reject": "No"
      }
    },
    "caaml": {
      "title": "CAAML",
      "caamlNotLoadedDialog": {
        "message": "¡No se ha podido cargar el CAAML!",
        "accept": "De acuerdo"
      },
      "noCaamlDialog": {
        "message": "No hay ningun boletín publicado disponible",
        "accept": "De acuerdo"
      }
    },
    "json": {
      "title": "JSON",
      "jsonNotLoadedDialog": {
        "message": "No se ha podido cargar el JSON",
        "accept": "De acuerdo"
      },
      "noJsonDialog": {
        "message": "No hay ningun boletín publicado disponible",
        "accept": "De acuerdo"
      }
    }
  },
  "training": {
    "training": "Training",
    "timestamp": "Training timestamp"
  },
  "admin": {
    "loadingStatistics": "Creando CSV ...",
    "server-configuration": {
      "title": "Servidor",
      "button": {
        "create": "Crear servidor",
        "save": "Guardar"
      },
      "table": {
        "name": "Nombre",
        "username": "Nombre de usuario",
        "password": "Password",
        "publishAt5PM": "Publicar a las 5PM",
        "publishAt8AM": "Publicar a las 8PM",
        "pdfDirectory": "Directorio para PDF",
        "htmlDirectory": "Directorio para HTML",
        "serverImagesUrl": "Ruta de acceso al servidor de imágenes",
        "mapsPath": "Ruta de acceso a mapas",
        "mediaPath": "Ruta a los ficheros multimedia",
        "mapProductionUrl": "URL para producción de mapas",
        "apiUrl": "API URL"
      },
      "success": "Configuración guardada con éxito",
      "error": "No se puede guardar la configuración"
    },
    "region-configuration": {
      "title": "Región",
      "button": {
        "create": "Crear región",
        "save": "Guardar"
      },
      "table": {
        "general": {
          "title": "General",
          "id": "ID",
          "microRegions": "Nombre de micro-regiones",
          "subRegions": "Sub-regiones",
          "superRegions": "Super-regiones",
          "neighborRegions": "Regiones vecinas"
        },
        "publication": {
          "title": "Publication",
          "publishBulletins": "Publicar la predicción de aludes",
          "publishBlogs": "Publicar las entradas de blog",
          "createCaamlV5": "Crear CAAML v5",
          "createCaamlV6": "Crear CAAML v6",
          "createJson": "Crear JSON",
          "createMaps": "Crear mapas",
          "createPdf": "Crear PDF",
          "sendEmails": "Enviar correos",
          "createSimpleHtml": "Crear HTML simple",
          "sendTelegramMessages": "Enviar mensajes de telegram",
          "sendPushNotifications": "Enviar notificaciones push",
          "pdfColor": "PDF color",
          "emailColor": "E-mail color",
          "pdfMapYAmPm": "Y para el mapa PDF (am/pm)",
          "pdfMapYFd": "Y para el mapa PDF (fd)",
          "pdfMapWidthAmPm": "Anchura del mapa para PDF (am/pm)",
          "pdfMapWidthFd": "Anchura del mapa para PDF (fd)",
          "pdfMapHeight": "Altura del mapa para PDF",
          "pdfFooterLogo": "Logotipo para el pie de página del PDF",
          "pdfFooterLogoColorPath": "Logotipo para el pie de página del PDF (color)",
          "pdfFooterLogoBwPath": "Logotipo para el pie de página del PDF (bw)",
          "mapXmax": "X del mapa máximo",
          "mapXmin": "X del mapa mínimo",
          "mapYmax": "Y del mapa máximo",
          "mapYmin": "Y del mapa mínimo",
          "simpleHtmlTemplateName": "Plantilla HTML simple",
          "geoDataDirectory": "Directorio de geodatos",
          "mapLogoColorPath": "Logotipo para el mapa (color)",
          "mapLogoBwPath": "Logotipo para el mapa (bw)",
          "mapLogoPosition": "Posición del logotipo para el mapa",
          "mapCenterLat": "Centrar posición del mapa (latitud)",
          "mapCenterLng": "Centrar posición del mapa (longitud)",
          "imageColorbarColorPath": "Barra de colores (color)",
          "imageColorbarBwPath": "Barra de colores (b/w)"
        },
        "components": {
          "title": "Components",
          "enableDangerSources": "Enable danger sources",
          "enableObservations": "Enable observations",
          "enableModelling": "Enable modelling",
          "enableWeatherbox": "Enable weather box"
        },
        "configuration": {
          "title": "Configuration",
          "showMatrix": "Mostrar matriz",
          "enableMediaFile": "Habilitar ficheros multimedia",
          "enableStrategicMindset": "Enable strategic mindset",
          "enableStressLevel": "Enable stress level",
          "enableAvalancheProblemCornices": "Habilitar problema de alud CORNISAS",
          "enableAvalancheProblemNoDistinctAvalancheProblem": "Habilitar problema de alud NINGÚN PROBLEMA DE ALUDES EVIDENTE",
          "enableEditableFields": "Editable textfields",
          "enableWeatherTextField": "Enable weather text field",
          "websiteMapRatio": "Website map ratio (n / m)"
        }
      },
      "success": "Configuración guardada con éxito",
      "error": "No se puede guardar la configuración"
    },
    "users": {
      "title": "Usuarios",
      "button": {
        "create": "Crear usuario"
      },
      "tooltip": {
        "edit": "Editar usuario",
        "changePassword": "Canvia la contraseña",
        "delete": "Eliminar usuario"
      },
      "table": {
        "name": "Nombre",
        "email": "Email",
        "organization": "Organización",
        "regions": "Regiones",
        "roles": "Roles"
      },
      "deleteUserModalDialog": {
        "message": "¿Quieres eliminar al usuario?",
        "accept": "Si",
        "reject": "No"
      },
      "deleteUser": {
        "success": "Usuario eliminado",
        "error": "El usuario no pudo ser eliminado"
      },
      "createUser": {
        "success": "Usuario creado",
        "error": "No se pudo crear el usuario"
      },
      "updateUser": {
        "success": "Usuario actualizado",
        "error": "El usuario no pudo ser actualizado"
      }
    },
    "createUser": {
      "table": {
        "image": "Image",
        "name": "Nombre",
        "email": "Email",
        "organization": "Organización",
        "password": "Password",
        "password2": "Repetir password",
        "roles": "Roles",
        "regions": "Regiones"
      },
      "error": {
        "name": {
          "required": "Por favor ingrese un nombre"
        },
        "email": {
          "required": "Por favor introduzca una dirección de correo eléctronico",
          "invalid": "La dirección de correo electrónico es inválida"
        },
        "password": {
          "required": "Por favor introduzca un password",
          "length": "La contraseña debe tener al menos 8 caracteres",
          "mismatch": "Las contraseñas no coinciden"
        }
      },
      "button": {
        "create": "Crear usuario",
        "update": "Actualizar usuario",
        "cancel": "Cancelar"
      }
    },
    "observations": {
      "table": "Tabla",
      "map": "Mapa",
      "gallery": "Gallery",
      "filters": "Flitros",
      "title": "Observaciones",
      "newObservation": "New observation",
      "exportObservations": "Export observations",
      "toggleFilterBar": "Show/hide filter bar",
      "button": {
        "submit": "Descargar"
      },
      "label": {
        "dateRange": "Periodo",
        "observer": "Observador"
      },
      "selectRegion": {
        "placeholder": "Región",
        "selectionLabel": "{0} region(s) selected"
      },
      "selectSources": {
        "placeholder": "Fuentes",
        "selectionLabel": "{0} source(s) selected"
      },
      "charts": {
        "charts": "Mapas",
        "nan": "Sin especificación",
        "classify": "Classificar",
        "label": "Etiqueta",
        "invert": "Invertir",
        "reset": "Restablecer",
        "aspect": {
          "caption": "Orientación"
        },
        "elevation": {
          "caption": "Altitud"
        },
        "stability": {
          "caption": "Estabilidad"
        },
        "observationType": {
          "caption": "Tipo de observación"
        },
        "importantObservation": {
          "caption": "Important"
        },
        "avalancheProblem": {
          "caption": "Problema de aludes"
        },
        "dangerPattern": {
          "caption": "Patrón de peligro"
        },
        "days": {
          "caption": "Día"
        }
      }
    },
    "blog": {
      "title": "Blog",
      "language": {
        "title": "Idioma",
        "de": "DE",
        "it": "IT",
        "en": "EN"
      },
      "all": {
        "tooltip": "Enviar correos electrónicos, Telegram y Push",
        "success": "{{prefix}}¡Correos electrónicos, Telegram y Push enviados con éxito!",
        "error": "{{prefix}}¡No se pudo enviar el correo electrónico, Telegram y Push!"
      },
      "email": {
        "tooltip": "Enviar correos electrónicos",
        "success": "{{prefix}}Correo electrónico enviado con éxito!",
        "error": "{{prefix}}¡El correo electrónico no pudo ser enviado!"
      },
      "telegram": {
        "tooltip": "Enviar a través del canal de Telegram",
        "success": "{{prefix}}¡Telegram enviado con éxito!",
        "error": "{{prefix}}¡No se pudo enviar el Telegram!"
      },
      "push": {
        "tooltip": "Enviar a través de notificaciones push",
        "success": "{{prefix}}¡Push enviado con éxito!",
        "error": "{{prefix}}¡No se pudo enviar el push!"
      }
    }
  },
  "settings": {
    "title": "Ajustes",
    "changePassword": {
      "title": "Cambiar la contraseña ",
      "oldPassword": {
        "label": "Contraseña actual ",
        "placeholder": "Contraseña actual "
      },
      "newPassword1": {
        "label": "Nueva contraseña",
        "placeholder": "Nueva contraseña"
      },
      "newPassword2": {
        "label": "Nuevo password (repetir)",
        "placeholder": "Nueva contraseña"
      },
      "submit": "Canvia la contraseña",
      "passwordChanged": "Contraseña cambiada con éxito",
      "passwordChangeError": "La contraseña no se ha podido cambiar",
      "passwordIncorrect": "Contraseña actual incorrecta",
      "error": {
        "oldPassword": {
          "required": "Por favor, introduce tu contraseña actual"
        },
        "newPassword": {
          "required": "Por favor, introduce una nueva contraseña",
          "mismatch": "Las contraseñas no coinciden"
        }
      }
    },
    "display": {
      "title": "Mostrar",
      "table": {
        "showCaaml": "Mostrar CAAML",
        "showJson": "MostrarJSON"
      }
    }
  },
  "statistics": {
    "title": "Estadisticas",
    "button": {
      "submit": "Descargar"
    },
    "label": {
      "dateRange": "Periodo",
      "extended": "Extensión",
      "duplicates": "Duplicados"
    }
  },

  "dangerRating": {
    "noRating": "Sin clasificar",
    "low": "débil",
    "moderate": "limitado",
    "considerable": "notable",
    "high": "fuerte",
    "veryHigh": "muy fuerte"
  },
  "avalancheProblem": {
    "newSnow": "Nieve reciente",
    "new_snow": "Nieve reciente",
    "windSlab": "Placas de viento",
    "wind_slab": "Placas de viento",
    "persistentWeakLayers": "Capas débiles persistentes",
    "persistent_weak_layers": "Capas débiles persistentes",
    "wetSnow": "Nieve húmeda",
    "wet_snow": "Nieve húmeda",
    "glidingSnow": "Deslizamientos basales",
    "gliding_snow": "Deslizamientos basales",
    "cornices": "Cornisas",
    "noDistinctAvalancheProblem": "Ningún problema de aludes evidente",
    "no_distinct_problem": "Ningún problema de aludes evidente",
    "favourableSituation": "Situación favorable",
    "favourable_situation": "Situación favorable"
  },
  "dangerPattern": {
    "dp1": "pp.1: capas débiles persistentes",
    "dp2": "pp.2: avalanchas de fondo",
    "dp3": "pp.3: lluvia",
    "dp4": "pp.4: frío sobre cálido / cálido sobre frío",
    "dp5": "pp.5: nevada reciente después de un largo periodo cálido",
    "dp6": "pp.6: frío, nieve suelta y viento",
    "dp7": "pp.7: zonas de poca nieve rodeadas de zonas con mayor espesor de nieve",
    "dp8": "pp.8: escarcha de superfície enterrada",
    "dp9": "pp.9: nieve redonda enterrada",
    "dp10": "pp.10: escenario de primavera"
  },
  "strategicMindset": {
    "title": "Strategic Mindset",
    "assessment": "High Uncertainty",
    "stepping_out": "Improving",
    "status_quo": "Status Quo",
    "stepping_back": "Deteriorating",
    "entrenchment": "Persistent Unstable",
    "free_ride": "Favorable",
    "high_alert": "High Alert",
    "spring_diurnal": "Spring Diurnal"
  },
  "observationType": {
    "TimeSeries": "Series temporales",
    "Incident": "Incidente",
    "Profile": "Perfil de nieve",
    "Closure": "Cierre",
    "Blasting": "Voladura",
    "Avalanche": "Alud",
    "Evaluation": "Evaluación",
    "SimpleObservation": "Observación simple",
    "Webcam": "Webcam"
  },
  "importantObservation": {
    "SnowLine": "Límite de nieve",
    "SurfaceHoar": "Escarcha de superfície",
    "Graupel": "Nieve granulada",
    "StabilityTest": "Test de estabilidad",
    "IceFormation": "Formación de hielo",
    "VeryLightNewSnow": "Nieve reciente muy ligera"
  },
  "aspect": {
    "N": "N",
    "NE": "NE",
    "E": "E",
    "SE": "SE",
    "S": "S",
    "SW": "SW",
    "W": "W",
    "NW": "NW"
  },
  "role": {
    "SUPERADMIN": "Super Administrator",
    "ADMIN": "Administrador",
    "FORECASTER": "Predictor",
    "FOREMAN": "Jefe",
    "OBSERVER": "Observador",
    "TRANSLATOR": "Translator"
  },
  "tendency": {
    "decreasing": "El peligro de aludes desciende",
    "steady": "El peligro de aludes se mantiene igual",
    "increasing": "El peligro de aludes aumenta"
  },
  "snowpackStability": {
    "unknown": "desconocido",
    "good": "buena",
    "fair": "regular",
    "poor": "pobre",
    "very_poor": "muy pobre"
  },
  "frequency": {
    "none": "(casi) ninguno",
    "few": "unos pocos",
    "some": "algunos",
    "many": "muchos"
  },
  "avalancheSize": {
    "small": "pequeña",
    "medium": "mediana",
    "large": "grande",
    "very_large": "muy grande",
    "extreme": "extremadamente grande"
  },
  "complexity": {
    "easy": "Fácil",
    "challenging": "Exigente",
    "complex": "Complejo"
  },
  "glidingSnowActivity": {
    "low": "débil",
    "medium": "mediana",
    "high": "fuerte"
  },
  "wetness": {
    "dry": "dry",
    "moist": "moist",
    "wet": "wet"
  },
  "characteristic": {
    "low": "débil",
    "medium": "mediana",
    "high": "fuerte",
    "very_high": "muy fuerte"
  },
  "thickness": {
    "thin": "thin",
    "thick": "thick"
  },
  "snowpackPosition": {
    "upper": "upper",
    "middle": "middle",
    "lower": "lower",
    "ground": "ground"
  },
  "creationProcess": {
    "radiation_recrystallization": "radiación",
    "diurnal_recrystallization": "diurnal",
    "melt_layer_recrystallization": "melt layer"
  },
  "recognizability": {
    "very_easy": "very easy",
    "easy": "easy",
    "hard": "hard",
    "very_hard": "very hard"
  },
  "distribution": {
    "isolated": "isolated",
    "specific": "specific",
    "widespread": "widespread"
  },
  "probability": {
    "likely": "likely",
    "possible": "possible",
    "unlikely": "unlikely"
  },
  "detailedDaytime": {
    "evening": "evening",
    "first_night_half": "first night half",
    "second_night_half": "second night half",
    "morning": "morning",
    "forenoon": "forenoon",
    "afternoon": "afternoon"
  },
  "slopeGradient": {
    "moderately_steep": "moderately steep",
    "steep": "steep",
    "very_steep": "very steep",
    "extremely_steep": "extremely steep"
  },
  "handHardness": {
    "fist": "F",
    "four_fingers": "4F",
    "one_finger": "1F",
    "pencil": "P",
    "knife": "K",
    "ice": "I"
  },
  "hardnessTendency": {
    "decreasing": "decreasing with height",
    "steady": "uniform",
    "increasing": "increasing with height"
  },
  "terrainType": {
    "gullies": "gullies",
    "bowls": "bowls",
    "pass_areas": "pass areas",
    "ridge_lines": "ridge lines"
  },
  "dangerSign": {
    "shooting_cracks": "shooting cracks",
    "whumpfing": "whumpfing"
  },
  "changeRegionDialog": {
    "message": "¿Quieres cambiar la región?",
    "accept": "Si",
    "reject": "No"
  },
  "zamg": {
    "table": "Tabla",
    "map": "Mapa",
    "region": "Región",
    "newSnow": "Nieve reciente"
  },
  "snowpack": {
    "plotType": {
      "LWC_stratigraphy": "nieve húmeda: contenido de agua líquida",
      "wet_snow_instability": "nieve húmeda: inestabilidad de nieve húmeda ",
      "Sk38_stratigraphy": "capas débiles persistentes: índice de estabilidad SK38",
      "stratigraphy": "capas débiles persistentes: estratigrafia de la nieve"
    },
    "aspect": {
      "flat": "llano",
      "north": "norte",
      "south": "sur"
    },
    "meteo": {
      "new_snow_plot_3day": "nieve reciente (3d)",
      "new_snow_plot_7day": "nieve reciente (7d)",
      "new_snow_plot_1month": "nieve reciente (31d)",
      "new_snow_plot_season": "nieve reciente (temporada)",
      "new_snow_plot_forecast": "nieve reciente (predicción)",
      "wet_snow_plot_3day": "nieve húmeda (3d)",
      "wet_snow_plot_7day": "nieve húmeda (7d)",
      "wet_snow_plot_1month": "nieve húmeda (31d)",
      "wet_snow_plot_season": "nieve húmeda (temporada)",
      "wet_snow_plot_forecast": "nieve húmeda (predicción)",
      "HS_table_24h": "tabla altura de nieve (1d)",
      "HS_table_72h": "tabla altura de nieve (3d)",
      "HS_table_season": "tabla altura de nieve (temporada)",
      "HS_table_forecast": "tabla altura de nieve (predicción)",
      "TA_table_24h": "tabla temperatura (1d)",
      "TA_table_72h": "tabla temperatura (3d)",
      "TA_table_season": "tabla temperatura (temporada)"
    }
  },
  "grainShape": {
    "PP": {
      "code": "PP",
      "class": "Precipitation Particles"
    },
    "PPgp": {
      "code": "PPgp",
      "class": "Nieve granulada"
    },
    "DF": {
      "code": "DF",
      "class": "Decomposing and Fragmented precipitation particles"
    },
    "RG": {
      "code": "RG",
      "class": "Rounded Grains"
    },
    "FC": {
      "code": "FC",
      "class": "Faceted Crystals"
    },
    "DH": {
      "code": "DH",
      "class": "Depth Hoar"
    },
    "SH": {
      "code": "SH",
      "class": "Surface Hoar"
    },
    "MF": {
      "code": "MF",
      "class": "Melt Forms"
    },
    "MFcr": {
      "code": "MFcr",
      "class": "Melt-freeze crust"
    },
    "IF": {
      "code": "IF",
      "class": "Ice Formations"
    }
  },
  "button": {
    "yes": "yes",
    "no": "no"
  },
  "observations": {
    "aspect": "Orientación",
    "authorName": "Nombre del autor",
    "avalancheLength": "Longitud de alud",
    "avalancheProblem": "Problema de aludes",
    "avalancheSize": "Tamaño de alud",
    "avalancheType": "Tipo de alud",
    "avalancheWidth": "Ancho de alud",
    "search": "Busca",
    "count": "# observaciones:",
    "layers": {
      "observers": "Observers",
      "weatherStations": "Weather stations",
      "webcams": "Webcams",
      "observations": "Observaciones"
    },
    "weatherStations": {
      "tooltips": {
        "globalRadiation": "Global radiation",
        "snowHeight": "Snow height",
        "snowDifference24h": "Snow difference 24h",
        "snowDifference48h": "Snow difference 48h",
        "snowDifference72h": "Snow difference 72h",
        "airTemperature": "Air temperature",
        "airTemperatureMax": "Air temperature Max",
        "airTemperatureMin": "Air temperature Min",
        "surfaceTemperature": "Surface temperature",
        "surfaceHoar": "Potential surface hoar formation",
        "surfaceHoarCalc": "Potential surface hoar formation (Lehning et. al.)",
        "dewPoint": "Dew point temperature",
        "relativeHumidity": "Relative humidity",
        "windDirection": "Wind direction",
        "windSpeed": "Wind speed",
        "windGust": "Wind gust"
      }
    },
    "button": {
      "add": "Añadir observación",
      "save": "Guardar observación",
      "delete": "Eliminar observación",
      "deleteConfirm": "Eliminar observación?",
      "discard": "Descartar"
    },
    "comment": "Comentario",
    "content": "Contenido",
    "dangerRating": "Clasificación del peligro",
    "elevation": "Altitud",
    "eventDate": "Fecha del evento",
    "eventType": "Tipo de evento",
    "eventTypes": {
      "IMPORTANT": "Evento importante",
      "NEIGHBOR_REGION": "Región vecina",
      "NORMAL": "Evento normal",
      "PERSON_DEAD": "Evento con personas implicadas (muertos)",
      "PERSON_INJURED": "Evento con personas implicadas (heridos)",
      "PERSON_NO": "Evento sin personas implicadas",
      "PERSON_UNINJURED": "Evento con personas implicadas (ilesos)",
      "PERSON_UNKNOWN": "Evento (desconocido)",
      "TRAFFIC": "Tránsito"
    },
    "fractureDepth": "Profundiad de cicatriz",
    "hasCoordinates": "Tiene coordenadas",
    "incline": "Inclinación",
    "latitude": "Laltitud",
    "locationName": "Localización",
    "longitude": "Longitud",
    "reportDate": "Fecha del informe",
    "showTable": "Tabla",
    "withoutCoordinates": "Observaciones sin coordenadas:",
    "sidebar": {
      "filter": {
        "title": "Filtrar"
      },
      "legend": {
        "title": "Leyenda"
      },
      "sources": {
        "title": "Fuentes de datos"
      },
      "views": {
        "title": "Vistas"
      }
    }
  },
  "units": {
    "cm": "cm",
    "mm": "mm",
    "m": "m"
  },
  "qfa": {
    "day": "Día",
    "time": "Hora",
    "back": "Volver",
    "parameters": {
      "DUST": "Polvo del Sahara ",
      "N": "Cobertura total de nubes en /8",
      "Nh": "Cobertura total de nubes altas en /8",
      "Nm": "Cobertura total de nubes medias en /8",
      "Nl": "Cobertura total de nubes bajas en /8",
      "N-CU": "Cobertura total de cúmulos en /8",
      "AUSL.T": "temperatura de desencadenamiento",
      "SHW-IX": "Índice Showalter",
      "WX -CUF": "tiempo significativo: NIL (ninguno), RASH (lluvia), TS (tormenta eléctrica)\n\n",
      "RR": "Precipitación total [mm]",
      "RR-str": "precipitaciones estratiformes",
      "CONV": "Precipitación convectiva [mm]",
      "SN": "Precipitación en forma de nieve [mm]",
      "SN--RA": "cota de nieve [m]",
      "QAO ": "{{value}} hPa eq. viento promedio, dirección i velocidad, [°] and [m/s]",
      "QAN": "viento promedio al suelo, dirección y velocidad, [°] and [m/s]",
      "QANmax": "viento máx. (rachas) al suelo [m/s]",
      "T ": "{{value}} hPa eq. Temperatura [°C]",
      "T m": "Temperatura a {{value}}m [°C]",
      "T 2m": "Temperatura a 2m [°C]",
      "Tk 2m": "Temperatura a 2m [°K]",
      "T GND": "temperatura del suelo",
      "Max-ADI": "Temperatura máxima de mezcla [°C]",
      "Min Max": "temperatura mín./máx. en las últimas 24h",
      "FROST": "altura de la isozero [m]",
      "W cm": "500 hPa eq. viento vertical [cm/s]",
      "W --": "700 hPa eq. viento vertical [cm/s]",
      "W s": "{{value}} hPa eq. viento vertical [cm/s]",
      "H ": "{{value}} hPa altura geopotencial [m]",
      "RF ": "{{value}} hPa eq. humedad relativa [%]"
    }
  }
}
